@font-face {
    font-family: 'Amazing Kids';
    font-style: normal;
    font-weight: 500;
    src: url('amazing_kids-webfont.eot'); /* IE9 Compat Modes */
    src: url('amazing_kids-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('amazing_kids-webfont.woff2') format('woff2'), /* Super Modern Browsers */
    url('amazing_kids-webfont.woff') format('woff'), /* Modern Browsers */
    url('amazing_kids-webfont.ttf') format('truetype'), /* Safari, Android, iOS */
    url('amazing_kids-webfont.svg#Oswald') format('svg'); /* Legacy iOS */
}
