

@font-face {
    font-family: 'Oswald Light';
    font-style: normal;
    font-weight: 300;
    src: url('oswald-v16-latin_latin-ext-300.eot'); /* IE9 Compat Modes */
    src: local('Oswald Light'), local('Oswald-Light'),
    url('oswald-v16-latin_latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('oswald-v16-latin_latin-ext-300.woff2') format('woff2'), /* Super Modern Browsers */
    url('oswald-v16-latin_latin-ext-300.woff') format('woff'), /* Modern Browsers */
    url('oswald-v16-latin_latin-ext-300.ttf') format('truetype'), /* Safari, Android, iOS */
    url('oswald-v16-latin_latin-ext-300.svg#Oswald') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Oswald Regular';
    font-style: normal;
    font-weight: 400;
    src: url('oswald-v16-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
    src: local('Oswald Regular'), local('Oswald-Regular'),
    url('oswald-v16-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('oswald-v16-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('oswald-v16-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */
    url('oswald-v16-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('oswald-v16-latin_latin-ext-regular.svg#Oswald') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Oswald Bold';
    font-style: normal;
    font-weight: 700;
    src: url('oswald-v16-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
    src: local('Oswald Bold'), local('Oswald-Bold'),
    url('oswald-v16-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('oswald-v16-latin_latin-ext-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('oswald-v16-latin_latin-ext-700.woff') format('woff'), /* Modern Browsers */
    url('oswald-v16-latin_latin-ext-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('oswald-v16-latin_latin-ext-700.svg#Oswald') format('svg'); /* Legacy iOS */
}


