@font-face {
  font-family: 'Lato Regular';
  font-style: normal;
  font-weight: 400;
  src: url('Lato-Regular.eot'); /* IE9 Compat Modes */
  src: url('Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('Lato-Regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('Lato-Regular.woff') format('woff'), /* Modern Browsers */
  url('Lato-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('Lato-Regular.svg') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Lato Italic';
  font-style: italic;
  font-weight: 400;
  src: url('Lato-Regular-Italic.eot'); /* IE9 Compat Modes */
  src: url('Lato-Regular-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('Lato-Regular-Italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('Lato-Regular-Italic.woff') format('woff'), /* Modern Browsers */
  url('Lato-Regular-Italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('Lato-Regular-Italic.svg') format('svg'); /* Legacy iOS */
}
@font-face {
  font-family: 'Lato Bold';
  font-style: normal;
  src: url('Lato-Bold.eot'); /* IE9 Compat Modes */
  src: url('Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('Lato-Bold.woff2') format('woff2'), /* Super Modern Browsers */
  url('Lato-Bold.woff') format('woff'), /* Modern Browsers */
  url('Lato-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
  url('Lato-Bold.svg') format('svg'); /* Legacy iOS */
}
