@use '@angular/material' as mat;
@import 'cms-theme.scss';
@import "cms-variables.scss";
@import "assets/fonts/amazing-kids/amazing-kids.css";
@import "assets/fonts/oswald/oswald.css";
@import "assets/fonts/arial-black/arial-black.css";
@import "assets/fonts/Roboto/roboto.css";
@import "assets/fonts/Lato/Lato.css";

/* for sidenav to take a whole page */
html, body {
  margin: 0;
  height: 100%;
  position: relative;
}

body {
  margin: 0;
  font-family: 'Lato Regular', sans-serif;
  font-weight: normal;
  box-sizing: border-box;

  * {box-sizing: inherit;}
  ul {list-style: none;}
  a {cursor: pointer;}
  p {margin-bottom: 0.5rem; }

  h1 { font-size: 1.5em; }
  h2 { font-size: 1.2em; }
  h3 { font-size: 1.0em; }
  h4 { font-size: 0.9em; }
  h5 { font-size: 0.9em; }
}

main {
  width: 100%;
}

.lshop-form {
  background-color: white;
  width: 100%;
}

.info {
  color: $__blue-light;
}

.disabledText {
  color: $cms-disabled-text;
}

.mat-mdc-card {
  margin-top: 5px;
  box-shadow: none !important;
  border-radius: 0 !important;
  background: #fafafa !important;
  border-left: 4px solid #e2e2e2 !important;
}

.__bold {font-weight: 600;}

section.header {
  margin: 5px 0;
  p {margin-bottom: 0;}
  img.icon {
    width: 15px;
    margin: 0 8px 0;
  }
}

section.card-link .mat-mdc-card:hover {
  background: #f5f5f5 !important;
}

.card-country {
  min-height: 100px;
  cursor: pointer;
}

.card-country .mat-mdc-card-avatar {
  width: 40px;
  height: 35px;
  box-shadow: 5px 7px 5px -1px rgb(0 0 0 / 18%);
  border-radius: 0 !important;
}

.card-country .mat-mdc-card-title {
  p {
    font-size: .9em;
    margin: 0;
  }
}

.mat-list-base {
  padding-top: 0 !important;
}

.list-vertical-scroll {
  max-height: calc(90vh - 95px);
  overflow-y: auto;
}

@media (max-width: 960px) {
  .list-vertical-scroll {
    height: auto;
    max-height: 100%;
  }
}

section.list {
  .mdc-list {
    margin: 0;
    padding: 0;
  }
}

section.list .mat-mdc-list-option .mdc-checkbox {
  display: none;
}

section.list .mat-mdc-list-option,
section.list .mat-mdc-list-item {
  margin: 3px 0;
  border-bottom: 1px solid #efefef;
  height: 80px;
}

section.list div.mat-mdc-list-text,
section.list div.mat-mdc-list-text{
  padding-right: 10px !important;
}

section.list .mat-mdc-list-option .mat-icon,
section.list .mat-mdc-list-item .mat-icon {
  width: 0 !important;
}

.mat-mdc-list-option,
.mat-mdc-list-item {
  background: #fafafa !important;
  overflow: hidden;
}

.mat-mdc-list-item.active,
.mat-mdc-list-option.active {
  background: $__grey-middle !important;
}

.mat-mdc-list-option.list-drag-item {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.mat-mdc-list-option.list-drag-item .mdc-checkbox {
  display: none;
}

.mat-mdc-list-option div.content,
.mat-mdc-list-item div.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mat-mdc-list-option div.content:has(+ mat-icon) {
  width: 90%;
}

.mat-mdc-list-option div.content:has(+ mat-icon) p {
  width: 100%;
  overflow: hidden;
}

section.icon-links .mat-icon {
  cursor: pointer;
  width: auto;
  height: auto;
  margin: 0 5px;
  font-size: .9em;
}

section.icon-links .mat-icon.fa-refresh.orange {
  color: white;
  background: $__orange-light;
  padding: 5px;
  text-align: center;
  width: 24px;
}

section.list .mat-mdc-list-option img,
section.list .mat-mdc-list-option .mat-icon,
.mat-mdc-list-option.list-drag-item img,
.mat-mdc-list-option.list-drag-item .mat-icon {
  width: 60px;
}

section.list .mat-mdc-list-option .mat-icon,
.mat-mdc-list-option.list-drag-item .mat-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  width: 16px !important;
  height: 16px !important;
}

section.list .mat-mdc-list-option.default-unit,
section.list .mat-mdc-list-option.switched-on.default-unit,
.mat-mdc-list-option.list-drag-item.default-unit,
.mat-mdc-list-option.list-drag-item.switched-on.default-unit {
  border-left: 4px solid rgb(245, 192, 20);
}

section.list .mat-mdc-list-option.switched-on,
section.list .mat-mdc-list-item.switched-on,
.mat-mdc-list-option.list-drag-item.switched-on {
  border-left: 4px solid rgb(68, 231, 19);
}

section.list .mat-mdc-list-option.switched-off,
section.list .mat-mdc-list-item.switched-off,
.mat-mdc-list-option.list-drag-item.switched-off {
  border-left: 4px solid rgb(248, 43, 7);
}

section.list .mat-mdc-list-option.replaced,
section.list .mat-mdc-list-item.replaced {
  background-color: #f5848c;
}

section.list .mat-mdc-list-option.replaced:hover,
section.list .mat-mdc-list-item.replaced:hover {
  background-color: #fc9ca3;
}

section.list mat-list-option.global-item,
section.list mat-list-item.global-item {
  background-color: $__blue-dark !important;
  p, mat-icon {
    color: whitesmoke !important;
  }
}

.weekdays-wrapper {
  background: $__grey-light;
  padding: 10px 0;
}

.img-container img {
  border: 1px solid grey;
  max-width: 800px;
  width: 100%;
}

table {
  border-collapse: collapse;
}

table th, table td {
  text-align: left;
  padding: 2px 4px;
  font-size: 0.9em;
  border-bottom: none;
  border-top: none;
}

.content-narrow {
  min-width: 65px !important;
  max-width: 65px !important;
}

.content-narrow section.header {
  overflow-x: hidden;
}

.content-narrow section.header p:first-child {
  overflow-x: hidden;
  white-space: nowrap;
  margin: 0;
}

.content-narrow section.list .list-label,
.content-narrow section.icon-links .mat-icon:not(:last-child) {
  display: none;
}

.content-narrow section.list .mat-icon img {
  width: 40px;
}

.content-narrow section.header {
  justify-content: flex-end !important;
}



.center {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
}

.filter-header {
  padding: 0 10px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  cursor: pointer;
}

.filter-header .mat-icon  {
  font-size: .9em;
  cursor: pointer;
  margin-left: 10px;
}

.card-dashboard {
  min-width: 340px;
  min-height: 500px;
  height: auto;
  width: auto;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    background: #003087;
    color: white;
    cursor: pointer;

    .mat-icon {
      height: 40px;
      width: 40px;
      font-size: 2.5em;
      opacity: 0.2;
      transition: all 300ms;
    }
  }

  .content {
    padding: 15px 10px 10px;
    background: #fafafa;

    table td {
      text-align: right;
    }

    .info-label {
      display: flex;
      align-items: center;
      justify-content: left;
      font-weight: normal;
      background: transparent;

      span {
        margin-left: 5px;
      }

      mat-icon {
        font-size: 1.2em;
        width: 18px;
        height: 18px;
      }
    }

    .mat-mdc-option-text > img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    .mat-mdc-form-field {
      width: 85%;
    }

    div.footer {
      width: 100%;

      button {
        display: block;
        margin: 20px auto;
        cursor: pointer;
      }
    }
  }
}

.card-dashboard:hover {
  .header {
    background: #003fa1;
    .mat-icon {
      transform: scale(1.2);
    }
  }
}


/*    Anpassungen =Material-Framework
  -------------------------------------*/
input, select, option, textarea, .mat-mdc-form-field, .mat-mdc-select, .mat-mdc-checkbox label {
  font-size: 14px !important;
}
label {font-size: 15px !important;}
.mat-mdc-floating-label {
  padding-bottom: 5px;
}
.mat-mdc-form-field {
  width: 100%;
}
.mat-mdc-option {
  min-height: 38px !important;
}
.mdc-text-field--filled,
.mat-mdc-form-field-infix {
  height: 54px !important;
  min-height: 54px !important;
}
.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}


.mat-mdc-form-field-subscript-wrapper {
  /*height: 15px !important;*/
}

.mat-error {
  font-size: 12px;
}

.material-icons.md-12 {
  font-size: 12px;
}

.material-icons.md-14 {
  font-size: 14px;
}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.mat-mdc-tab-list {
  background-color: $__grey-middle;
}

.tab-small .mat-mdc-tab-list {
  background-color: transparent;
  border-bottom: 2px solid $__grey-middle;
  margin-bottom: 10px;
}

.tab-small .mat-mdc-tab.mdc-tab {
  height: 20px;
  padding: 0 14px;
  min-width: 40px;
  background: transparent;
}

.tab-small .mdc-tab__content i.fa {
  padding-left: 5px;
}

.mat-mdc-paginator-container {
  margin: 20px 0;
}

mat-spinner {
  zoom: 0.5;
}

/*    Slider/Teaser =Allgemein
    -------------------------------------*/

button.btn {
  font-weight: 400;
  font-size: 1em;
  letter-spacing: 0.02em;
  transition: 300ms;
}

button.btn-blue {
  background-color: $__btn-blue;
}

button.btn-blue:hover, button.btn-blue:active {
  color: white;
  background-color: $__btn-blue-hover;
}

button.btn-grey {
  color: white;
  background-color: $__btn-grey;
}

button.btn-grey:hover, button.btn-grey:active {
  color: white;
  background-color: $__btn-grey-hover;
}

button.btn-white {
  color: black;
  background-color: white;
  border: 1px solid #c3c3c3 !important;
}

button.btn-white:hover, button.btn-white:active {
  color: black;
  background-color: #f3f3f3;
}

button.btn-black {
  color: white;
  background-color: black;
}

button.btn-black:hover, button.btn-black:active {
  color: white;
  background-color: #2a2a2a;
}

button.btn-orange {
  color: white;
  background-color: $__orange-light;
}

button.btn-orange:hover, button.btn-orange:active {
  color: white;
  background-color: $__orange-dark;
}

button.btn-red {
  color: white;
  background-color: $__red-dark;
}

button.btn-red:hover, button.btn-red:active {
  color: white;
  background-color: $__red-light;
}

/*    Anpassungen =Material-Framework
    -------------------------------------*/

.table > :not(:first-child) {
  border-top: none !important;
}

.mat-toolbar.mat-primary {
  z-index: 1000;
}

.cdk-overlay-container .mat-dialog-container {
  border-radius: 0;
  height: auto;
}

.table > :not(caption) > * > * {
  padding: 0 !important;
}

#page-root {
  background: url("/assets/images/keycloak-bg.png")  no-repeat center center fixed;
  background-size: cover;
  height: 100%;

  .header-wrapper {
    padding: 80px 0 40px 0;
  }

  .header-wrapper h1 {
    font-size: 1.8rem;
    letter-spacing: 4px;
    line-height: 1.2em;
    white-space: normal;
    text-transform: uppercase;
    color: white;
    text-align: center;
    font-weight: normal;
  }

  #kc-page-title {
    text-align: center;
    font-weight: normal;
    font-size: 1.3rem;
  }

  .form-wrapper {
    margin: 0 auto;
    padding: 30px 40px;
    max-width: 500px;
    border-top: 4px solid #06c;
    background: white;
  }

  .mat-mdc-text-field-wrapper {
    padding-top: 0px !important;
    padding-bottom: 0px !important;;
    background: transparent;
  }

  .btn-login {
    margin-top: 20px;
    width: 100%;
    background-color: #06c;
    color: white;
    border-radius: 0;
  }

  .btn-login:hover {
    background-color: #004993;
  }

  .splash {
    width: 80px;
    height: 80px;
    margin: 80px auto 0 auto;
    text-align: center;

    .lds-roller {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
    }
    .lds-roller div {
      animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      transform-origin: 40px 40px;
    }
    .lds-roller div:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: #d4e4fc55;
      margin: -4px 0 0 -4px;
    }
    .lds-roller div:nth-child(1) {
      animation-delay: -0.036s;
    }
    .lds-roller div:nth-child(1):after {
      top: 63px;
      left: 63px;
    }
    .lds-roller div:nth-child(2) {
      animation-delay: -0.072s;
    }
    .lds-roller div:nth-child(2):after {
      top: 68px;
      left: 56px;
    }
    .lds-roller div:nth-child(3) {
      animation-delay: -0.108s;
    }
    .lds-roller div:nth-child(3):after {
      top: 71px;
      left: 48px;
    }
    .lds-roller div:nth-child(4) {
      animation-delay: -0.144s;
    }
    .lds-roller div:nth-child(4):after {
      top: 72px;
      left: 40px;
    }
    .lds-roller div:nth-child(5) {
      animation-delay: -0.18s;
    }
    .lds-roller div:nth-child(5):after {
      top: 71px;
      left: 32px;
    }
    .lds-roller div:nth-child(6) {
      animation-delay: -0.216s;
    }
    .lds-roller div:nth-child(6):after {
      top: 68px;
      left: 24px;
    }
    .lds-roller div:nth-child(7) {
      animation-delay: -0.252s;
    }
    .lds-roller div:nth-child(7):after {
      top: 63px;
      left: 17px;
    }
    .lds-roller div:nth-child(8) {
      animation-delay: -0.288s;
    }
    .lds-roller div:nth-child(8):after {
      top: 56px;
      left: 12px;
    }
    @keyframes lds-roller {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

@media (max-width: 767.98px) {
  #page-root {
    background: white;
    background-size: auto;
    height: auto;

    .header-wrapper {
      padding: 40px 0;
    }

    .header-wrapper h1 {
      font-size: 1.4rem;
      letter-spacing: 2px;
      text-transform: uppercase;
      text-align: center;
      color: black;
      margin: 0;
    }

    .form-wrapper {
      padding: 30px 20px;
      max-width: 100%;
    }

    .splash {
      .lds-roller div:after {
        background: #0d6efd;
      }
    }
  }
}

.notification-article-content {
  padding: 20px;
  max-width: 960px;
  width: auto;
  margin: 0 auto 10px auto;
  border: 1px solid #dadce0;
}

.notification-article-content.notification-default {
  background: #f3f3f3;;
}

.notification-article-content.notification-info {
  background: #d1daf1;
}

.notification-article-content.notification-warning {
  background: #fff6da;
}

.notification-article-content.notification-danger {
  background: #f8d7da;
}

.__notification-wrapper {
  position: fixed;
  bottom: 0;
  z-index: 10000;
  background: #f3f3f3;
  width: 100%;
  margin: 0 auto;

  .notification-header {
    width: 100%;
    background: $__blue-light;
    color: white;

    .inner {
      display: flex;
      align-items: center;
      max-width: 960px;
      width: auto;
      margin: 0 auto;
      padding: 0 15px;

      .icon {
        font-size: 1.2em;
        cursor: default;
      }
      .headline {
        font-size: .9em;
        margin-left: 12px;
      }
    }
  }

  .notification-content {
    padding: 20px;
    max-height: 300px;
    overflow-y: auto;
    max-width: 960px;
    width: auto;
    margin: 0 auto;

    p {margin: 0; font-size: 14px; }

    .notification-info h4,
    .notification-warning h4,
    .notification-danger h4
    {
      font-weight: bold;
      font-size: 1.1em;
      padding: 0;
    }

    .notification-default {
      border-left: 5px solid #e1e1e1;
    }

    .notification-info {
      border-left: 5px solid #c6d8f9;
    }

    .notification-warning {
      border-left: 5px solid #ffdf70;
    }

    .notification-danger {
      border-left: 5px solid #ff8692;
    }
  }

  .notification-content div.box {
    padding: 0 0 20px 20px;
    margin-bottom: 5px;
  }

  .notification-content div.box:last-child {
    padding: 0 0 0 20px;
  }

  .notification-footer {
    width: 100%;
    height: 60px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    border-top: 1px solid lightgrey;

    .btn {
      margin-left: 5px;
      background: $__blue-light;
      font-size: 1em;
      color:white;
    }
  }
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d7d7d7;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #afafaf;
}

/* restyle smoothScrollbar */
.scrollbar-thumb {
  background: rgba(15, 15, 15, .2) !important;
}

.scrollbar-thumb,
.scrollbar-track-y {
  width: 5px !important;
}



