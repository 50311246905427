
@font-face {
	font-family: 'Arial Black';
	src: url('arialblack.eot?v=4.4.0');
	src: url('arialblack.eot?#iefix&v=4.4.0')
	format('woff2'), url('arialblack.woff?v=4.4.0')
	format('woff'), url('arialblack.ttf?v=4.4.0')
	format('truetype');
	font-weight: normal;
	font-style: normal;
}
