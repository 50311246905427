@use '@angular/material' as mat;
$palette-primary: mat.define-palette(mat.$grey-palette, 500);
$palette-accent:  mat.define-palette(mat.$deep-orange-palette);
$palette-warn:  mat.define-palette(mat.$red-palette);

$primary: mat.get-color-from-palette($palette-primary);
$primary-contrast: mat.get-color-from-palette($palette-primary, default-contrast);

$accent: mat.get-color-from-palette($palette-accent);
$accent-contrast: mat.get-color-from-palette($palette-accent, default-contrast);

$warn: mat.get-color-from-palette($palette-warn);
$warn-contrast: mat.get-color-from-palette($palette-warn, default-contrast);

$cms-theme: mat.define-light-theme((
        color: (
            primary: $palette-primary,
            accent: $palette-accent,
            warn: $palette-warn
        ),
        typography: mat.define-typography-config(
            $font-family: 'Lato Regular, sans-serif',
            $headline-1: mat.define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
            $headline-2: mat.define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
            $headline-3: mat.define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
            $headline-4: mat.define-typography-level(0.9em, 1em, 400),
            $headline-5: mat.define-typography-level(0.9em, 1em, 400),
            $body-1: mat.define-typography-level(0.9em, 1em, 400)
        )
));

$cms-primary-color: mat.get-color-from-palette(map-get($cms-theme, foreground), text);
$cms-theme-text: mat.get-color-from-palette(map-get($cms-theme, foreground), text);
$cms-disabled-text: mat.get-color-from-palette(map-get($cms-theme, foreground), disabled-text);


