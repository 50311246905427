@font-face {
    font-family: 'Roboto Regular';
    font-style: normal;
    src: url('Roboto-Regular.ttf');
    src: local('Roboto Regular'), local('Roboto-Regular'),
    url('Roboto-Regular.ttf') format('truetype')
}
@font-face {
    font-family: 'Roboto Bold';
    src: url('Roboto-Bold.ttf');
    src: local('Roboto Bold'), local('Roboto-Bold'),
    url('Roboto-Bold.ttf') format('truetype')
}
@font-face {
    font-family: 'Roboto Black';
    src: url('Roboto-Black.ttf');
    src: local('Roboto Black'), local('Roboto-Black'),
    url('Roboto-Black.ttf') format('truetype')
}
@font-face {
    font-family: 'Roboto Light';
    src: url('Roboto-Light.ttf');
    src: local('Roboto Light'), local('Roboto-Light'),
    url('Roboto-Light.ttf') format('truetype')
}
@font-face {
    font-family: 'Roboto Italic';
    src: url('Roboto-Italic.ttf');
    src: local('Roboto Italic'), local('Roboto-Italic'),
    url('Roboto-Italic.ttf') format('truetype')
}
@font-face {
    font-family: 'Roboto Thin';
    src: url('Roboto-Thin.ttf');
    src: local('Roboto Thin'), local('Roboto-Thin'),
    url('Roboto-Thin.ttf') format('truetype')
}
@font-face {
    font-family: 'RobotoSlab Light';
    src: url('RobotoSlab-Light.ttf');
    src: local('RobotoSlab Light'), local('RobotoSlab-Light'),
    url('RobotoSlab-Light.ttf') format('truetype')
}
@font-face {
    font-family: 'RobotoSlab Regular';
    src: url('RobotoSlab-Regular.ttf');
    src: local('RobotoSlab Regular'), local('RobotoSlab-Regular'),
    url('RobotoSlab-Regular.ttf') format('truetype')
}
@font-face {
    font-family: 'RobotoSlab Black';
    src: url('RobotoSlab-Black.ttf');
    src: local('RobotoSlab Black'), local('RobotoSlab-Black'),
    url('RobotoSlab-Black.ttf') format('truetype')
}

