$__grey-extra-dark: #3a3943;
$__grey-dark: #99a1a5;
$__grey-middle: #e3e7e8;
$__grey-light: #f9f9f9;
$__blue-dark: #002363;
$__blue-light: #003087;
$__orange-dark: #ff390e;
$__orange-light: #ff5c39;
$__red-dark: #d40404;
$__red-light: #fc0b0b;
$__section-margin: 60px 0;
$__teaser-box-lg-height: 250px;
$__teaser-box-md-height: 180px;
$__teaser-box-sm-height: 130px;
$__font-size-sm: 1.4rem;
$__font-size-xs: 1rem;

$__btn-grey: #858A8DFF;
$__btn-grey-hover: #8a9090;
$__btn-blue: #003087;
$__btn-blue-hover: #0056f2;
$__btn-font-size: 1.2em;